(function (angular, _, undefined) {
    "use strict";
    var blog = angular.module("mikesBlog");

    blog.controller("mikesBlogList.ctrl", ["$scope", "$http", "$filter", "BlogService", "$localStorage",
        function ($scope, $http, $filter, Blog, $localStorage) {
        var vm = this;

        $scope.listFilter = [];

        vm.dateOptions = {
            year: "numeric",
            month: "short",
            day: "numeric"
        };

        vm.cards = $localStorage.blog || [];

        Blog.getList()
            .then(function () {
                vm.cards = $localStorage.blog;
            },
            function (e) {
                console.error("Error loading blog list", e);
            });

        $scope.search = {
            filter: [],
            placeholder: "Add Filter"
        };

        $scope.$watchCollection("search.filter", function (newList, oldList) {
            $scope.listFilter = newList;
        });

    }]);

    blog.controller("mikesBlogPost.ctrl", ["$scope", "$state", "$stateParams", "$http", "BlogService", "$localStorage",
        function ($scope, $state, $stateParams, $http, Blog, $localStorage) {
        var vm = this,
            id = _.get($stateParams, "id", "");

        vm.blogPosts = _.values($localStorage.blog || {});
        Blog.getPost(id)
            .then(function () {
                vm.blogPost = $localStorage.blog[_.findIndex($localStorage.blog, { title: id })];
            },
            function (e) {
                console.error("Error loading blog post", id, e);
            });
    }]);

})(angular, _);
